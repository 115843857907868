import React, { useEffect, useState } from "react";
import io from 'socket.io-client';

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";
import socket from '../../../helpers/socket-context';
interface IndexProps {
  isChannel: boolean;
}

const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
	const [messages, setMessages] = useState([
		{ text: 'Hello! Before we get started, let me guide you on how to optimize your experience. Although I\'m a product of artificial intelligence, consider me as a friend who\'s eager to assist. In the next few moments, I\'ll be posing a series of questions to you. The aim of these questions is to gain a deeper understanding of who you are, enabling me to create an exceptional dating profile for you.', sender: 'bot' },
		{ text: 'Once I\'ve gathered sufficient information, I\'ll activate the \'Write Profile\' button for you. You\'re welcome to continue our conversation, or you can choose to wrap up. Are you ready to begin?', sender: 'bot' }
	]);
	const [changeButtonActive, setChangeButtonActive] = useState(false);
  const {
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
    chatUserConversations: state.Chats.chatUserConversations,
    isUserMessageSent: state.Chats.isUserMessageSent,
    isMessageDeleted: state.Chats.isMessageDeleted,
    isMessageForwarded: state.Chats.isMessageForwarded,
    isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
    isImageDeleted: state.Chats.isImageDeleted,
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
      setTimeout(() => {
          setMessages([...messages, { text: data.text, sender: 'user' }]);
          socket.emit('chat message', data.text);
      }, 200);


  };


	useEffect(() => {
		socket.on('chat message', message => {
			setMessages(messages => [...messages, { text: message, sender: 'bot' }]);
			if (message) {

			}
			if (message.includes('click the Write Profile') || message.includes(`click the "Write Profile"`)    ) {
				setChangeButtonActive(true);
			}

			if (message === 'There was a problem sending the request. Try again later.') {
				// setError(message);
			}
		});

		socket.on('error', error => {
			// setError(error.message);
			// setIsProfileLoading(false);
		});

		return () => {
			socket.off('chat message');
			socket.off('error');
		};
	}, []);

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      dispatch(getChatUserConversations(chatUserDetails.id));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };
  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
				messages={messages}
        showProfile={changeButtonActive}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
				changeButtonActive={changeButtonActive}
      />
    </>
  );
};

export default Index;
