import React from "react";
import { Button, Col, Row } from "reactstrap";
import logo from '../../../assets/images/logo-symbol.png';
import { useDispatch } from 'react-redux';

// actions
import { changeSelectedChat } from "../../../redux/actions";
const Welcome = () => {
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(changeSelectedChat(7)); // Set selectedChat to true
	};

  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div style={{display:'flex', justifyContent:'center', alignItems: 'center', marginBottom:'24px'}}>
								<img src={logo} style={{width: '100px'}} /><h1 className={"welcome-message"}>Love Script</h1>
              </div>
              <h4 style={{margin:'25px 0'}}> Our savvy AI chatbot spices up your dating profile for unbeatable success!</h4>
              <p className="text-muted mb-4">
								Are you new to the dating scene or simply looking to enhance your current profile? Love Script is here to assist! Our advanced AI chatbot will ask you a series of thoughtful questions, analyze your responses, and provide tailored recommendations to elevate your dating profile.
              </p>
              <Button type="button" className="btn btn-primary w-lg" onClick={handleClick}>
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
