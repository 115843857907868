import logo from '../../../assets/images/logo-symbol.png';
import ellie from '../../../assets/images/ellie.png';
import React, {useEffect} from 'react';
import socket from '../../../helpers/socket-context';
import Loader from "../../../components/Loader";
function ChatMessage ({data, isLoadingChat}) {
	if (data.sender === 'bot') {
		return (
			<li className="chat-list ">
				<div className="conversation-list">
					<div className="chat-avatar">
						<img src={ellie} alt="" />
					</div>
					<div className="user-chat-content">
						<div className="ctext-wrap">
							<div className="ctext-wrap-content">
								<p className="mb-0 ctext-content">{data.text}</p>
								{ isLoadingChat && <Loader />}
							</div>
						</div>

						<div className="conversation-name">Ellie Campbell
							{/*<small className="text-muted mb-0 ms-2">08:57pm</small>*/}
						</div>
					</div>
				</div>
			</li>
		)

	} else {
		return (
			<li className="chat-list right message-fade">
				<div className="conversation-list">
					<div className="chat-avatar">
						<img src={logo} alt="" />
					</div>
					<div className="user-chat-content">
						<div className="ctext-wrap">
							<div className="ctext-wrap-content">
								<p className="mb-0 ctext-content">{data.text}</p>
								{ isLoadingChat && <Loader />}
							</div>
						</div>
						<div className="conversation-name">Me
							{/*<small className="text-muted mb-0 ms-2">08:57pm</small>*/}
						</div>

					</div>
				</div>
			</li>
		)

	}
}

export default ChatMessage;
