import React, { useEffect, useRef, useCallback, useState } from "react";
import { io } from "socket.io-client";
import robot from '../../../assets/images/robot.jpeg';
import socket from '../../../helpers/socket-context';
import TypingIndicator from './TypingIndicator';

// hooks
import { useRedux } from "../../../hooks/index";
import { useProfile } from "../../../hooks";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
import ForwardModal from "../../../components/ForwardModal";

// actions
import { forwardMessage, deleteImage } from "../../../redux/actions";

// interface
import { MessagesTypes } from "../../../data/messages";
import ChatMessage from './ChatMessage';
import ellie from "../../../assets/images/ellie.png";

// ... Other imports and component code ...

interface ConversationProps {
	chatUserDetails: any;
	chatUserConversations: any;
	onDelete: (messageId: string | number) => any;
	onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
	isChannel: boolean;
	messages: any;
	showProfile: boolean;
}

const Conversation = ({ chatUserDetails, chatUserConversations, onDelete, onSetReplyData, isChannel, messages, showProfile }: ConversationProps) => {
	// ... Existing code ...

	const [isLoadingChat, setIsLoadingChat] = useState(false);
	const [showTypingIndicator, setShowTypingIndicator] = useState(false);

	// global store
	const { dispatch, useAppSelector } = useRedux();

	const { userProfile } = useProfile();

	const { getUserConversationsLoading, isMessageForwarded } = useAppSelector(
		(state: any) => ({
			getUserConversationsLoading: state.Chats.getUserConversationsLoading,
			isMessageForwarded: state.Chats.isMessageForwarded,
		})
	);



	const ref = useRef<any>();
	const scrollElement = useCallback(() => {
		if (ref && ref.current) {
			const listEle = document.getElementById("chat-conversation-list");
			let offsetHeight = 0;
			if (listEle) {
				offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
			}
			if (offsetHeight) {
				ref.current.getScrollElement().scrollTo({ top: offsetHeight, behavior: "smooth" });
			}
		}
	}, [ref]);

	// Loading state for chat messages

	useEffect(() => {
		if (isLoadingChat) {
			const timer = setTimeout(() => {
				setShowTypingIndicator(true);
			}, 500);
			return () => clearTimeout(timer);
		} else {
			setShowTypingIndicator(false);
		}
	}, [isLoadingChat]);
	useEffect(() => {
		socket.on('chat started', () => {
			console.log('show loading indicator')
			setIsLoadingChat(true); // Show loading indicator
		});

		socket.on('chat ended', () => {
			console.log('hide loading indicator')
			setIsLoadingChat(false); // Hide loading indicator
		});

		// Clean up the event listeners when component unmounts
		return () => {
			socket.off('chat started');
			socket.off('chat ended');
		};
	}, []);

	useEffect(() => {
		if (ref && ref.current) {
			ref.current.recalculate();
		}
	}, []);

	useEffect(() => {
		if (messages) {
			scrollElement();
		}
	}, [messages, scrollElement]);

	/*
	forward message
	*/
	const [forwardData, setForwardData] = useState<null | MessagesTypes | undefined>();
	const [isOpenForward, setIsOpenForward] = useState<boolean>(false);

	const onOpenForward = (message: MessagesTypes) => {
		setForwardData(message);
		setIsOpenForward(true);
	};

	const onCloseForward = () => {
		setIsOpenForward(false);
	};

	const onForwardMessage = (data: any) => {
		const params = {
			contacts: data.contacts,
			message: data.message,
			forwardedMessage: forwardData,
		};
		dispatch(forwardMessage(params));
	};

	useEffect(() => {
		// messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
	}, [messages]);

	useEffect(() => {
		if (isMessageForwarded) {
			setIsOpenForward(false);
		}
	}, [isMessageForwarded]);

	/*
	image delete
	*/
	const onDeleteImage = (messageId: string | number, imageId: string | number) => {
		dispatch(deleteImage(chatUserDetails.id, messageId, imageId));
	};

	const handleChange = () => {
		socket.emit('change prompt', );
	}

	return (
		<AppSimpleBar
			scrollRef={ref}
			className="chat-conversation p-3 p-lg-4 positin-relative"
		>
			<ul
				className="list-unstyled chat-conversation-list"
				id="chat-conversation-list"
			>
				{messages.map((message:object, index:number) => (<ChatMessage data={message} isLoadingChat={false}/>))}
				{
					showProfile ? 				<div className="conversation-list">
						<div className="chat-avatar">
							<img src={ellie} alt="" />
						</div>
						<div className="user-chat-content">
							<div className="ctext-wrap">
								<button onClick={handleChange} type="button" className="btn btn-primary w-lg btn-md" style={{marginTop: '40px'}}>Write Profile</button>
							</div>
							<div className="conversation-name">Ellie Campbell</div>
						</div>
					</div> : null
				}

				{showTypingIndicator ? <li className={`chat-list message-fade`}>
					<div className="conversation-list">
						<div className="chat-avatar">
							<img src={ellie} alt="" />
						</div>
						<div className="user-chat-content">
							<div className="ctext-wrap">
								<div className="ctext-wrap-content">
									<p className="mb-0 ctext-content">	<TypingIndicator /></p>
								</div>
							</div>
							<div className="conversation-name">Ellie Campbell</div>
						</div>
					</div>
				</li> : null}
			</ul>
			{isOpenForward && (
				<ForwardModal
					isOpen={isOpenForward}
					onClose={onCloseForward}
					forwardData={forwardData}
					chatUserDetails={chatUserDetails}
					onForward={onForwardMessage}
				/>
			)}
		</AppSimpleBar>
	);
};

export default Conversation;
