import { ContactTypes } from "./contacts";
import { myData } from "./myProfile";

export interface AttachmentTypes {
	id: number;
	name: string;
	downloadLink: string;
	desc: string;
}

export interface ImageTypes {
	id: number;
	downloadLink: string;
}

export interface MessagesTypes {
	mId: number;
	text?: string;
	time: string;
	meta: {
		receiver: string | number;
		sender: string | number;
		userData?: ContactTypes;
		sent: boolean;
		received: boolean;
		read: boolean;
		isForwarded?: boolean;
	};
	attachments?: AttachmentTypes[];
	image?: ImageTypes[];
	newimage?: ImageTypes[];
	replyOf?: MessagesTypes;
}

export interface ConversationTypes {
	conversationId: string | number;
	userId: string;
	isGroupConversation?: boolean;
	typingUser?: string | number;
	messages: MessagesTypes[];
}

export const myId = myData.uid;

const conversations: ConversationTypes[] = [
	{
		conversationId: 1,
		userId: "614ecab4ac946a9bdafa4e3b",
		typingUser: "614ecab4ac946a9bdafa4e3b",
		messages: [
			{
				mId: 1,
				text: "Good morning 😊",
				time: new Date().toISOString(),
				meta: {
					receiver: myId,
					sender: "614ecab4ac946a9bdafa4e3b",
					sent: true,
					received: true,
					read: true,
				},
			},
			{
				mId: 2,
				text: "Good morning, How are you? What about our next meeting?",
				time: new Date().toISOString(),
				meta: {
					receiver: "614ecab4ac946a9bdafa4e3b",
					sender: myId,
					sent: false,
					received: false,
					read: false,
				},
			},
			{
				mId: 3,
				text: "Yeah, everything is fine. Our next meeting is tomorrow at 10.00 AM",
				time: new Date().toISOString(),
				meta: {
					receiver: myId,
					sender: "614ecab4ac946a9bdafa4e3b",
					sent: true,
					received: true,
					read: true,
				},
			},
		],
	},
	{
		conversationId: 2,
		userId: "another-user-id",
		typingUser: "another-user-id",
		messages: [
			{
				mId: 1,
				text: "Hello! How's it going?",
				time: new Date().toISOString(),
				meta: {
					receiver: myId,
					sender: "another-user-id",
					sent: true,
					received: true,
					read: true,
				},
			},
			{
				mId: 2,
				text: "Hi! I'm doing great. How about you?",
				time: new Date().toISOString(),
				meta: {
					receiver: "another-user-id",
					sender: myId,
					sent: false,
					received: false,
					read: false,
				},
			},
		],
	},
];

const onChangeConversations = (newData: ConversationTypes[]) => {
	// Update the conversations variable or perform any necessary logic here
};

export { conversations, onChangeConversations };
