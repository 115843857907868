import React from 'react';
import './typingindicator.css'; // Import your CSS file here

function TypingIndicator() {

    return (
        <div className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export default TypingIndicator;
